import React from 'react';
import iconCalendar from '../assets/images/intensive-spanish/duration.svg';
import iconClock from '../assets/images/intensive-spanish/schedule.svg';
import iconLevels from '../assets/images/intensive-spanish/levels.svg';

const CourseSchedule = ({ levels, startDates, schedule }) => {
  return (
    <section className="course-schedule-section">
      <div className="container">
        <div className="course-schedule__wrapper">
          {/* <div className="c-title-13  schedule-title">Course Schedule & Levels</div> */}
          <div className="schedule-wrapper">
            <div className="schedule no-dots">
              <img src={iconLevels} alt="Levels" />
              <span className="schedule__title">Levels:</span> <b>{levels?.() ?? levels}</b>
            </div>{' '}
            <div className="schedule">
              <img src={iconCalendar} alt="calendar" />
              <span className="schedule__title">Start dates:</span> <b>{startDates}</b>
            </div>
            <div className="schedule">
              <img src={iconClock} alt="clock" />
              <span className="schedule__title">Schedule:</span>
              {schedule()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseSchedule;
