import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import Link from '/src/components/LocalizedLink';

import Layout from '../../components/Layout';

import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';

import '../../assets/styles/pages/terms-and-privacy-page.scss';

const ApplyThankYou = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const itemId = localStorage.getItem('leadItemId');
    const appliedDeposit = localStorage.getItem('appliedDeposit');

    if (!itemId || !sessionId) return;

    const revalidateStatus = async () => {
      const res = await fetch('/api/crm-update-lead-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          appliedDeposit,
          itemId,
        }),
      });
      const data = await res.json();
    };

    revalidateStatus();
  }, [sessionId]);

  return (
    <Layout
      title="Thanks for your application | Iberia Language Academy"
      description="Many thanks for your application!"
    >
      <div className="terms-and-privacy-page">
        <div className="container">
          <div className="wrapper">
            <h1 className="c-title-42" style={{ textAlign: 'left', marginBottom: 40 }}>
              Application received
            </h1>
            <div className="subsection">
              <p className="subsection__par">Many thanks for your application!</p>
              <p className="subsection__par">
                Your application has been received and we will review it shortly. This typically
                takes under a day (Monday-Friday) and we’ll contact you soon to arrange a video /
                phone / in-person interview.
              </p>
              <p className="subsection__par">
                We will be in touch via email,{' '}
                <b> please check your spam folder if you do not hear from us!</b>
              </p>
              <p className="subsection__par">Iberia Language Academy Team</p>
              <Link to="/" className="c-btn c-btn--red mt-50">
                Return to the home page
                <IconArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyThankYou;
