import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../../../components/Layout';

import iconTick from '../../../assets/images/svg/tick.svg';
import IconArrowRight from '../../../assets/images/svg/arrow-right.inline.svg';

import '../../../assets/styles/pages/special-offers-page.scss';

const SpecialOffers = () => {
  return (
    <Layout
      title={'Early-bird Discount Offers on Barcelona Spanish Courses | 2025'}
      description={
        'At ILA we offer a wide variety of courses to suit all needs and budgets. Here we have some early-bird special offers on our most popular packages.'
      }
    >
      <div className="special-offers-page">
        <section className="first-section">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <div className="wrapper__inner">
                  <h1 className="c-title-42 first-section__title">
                    <FormattedMessage id="specialOffers.sec1.title" />
                  </h1>
                  <p className="c-text-18 first-section__description">
                    <FormattedMessage id="specialOffers.sec1.description" />{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="row c-benefit-list offseted">
              <div className="col-12 col-md-6">
                <div className="item">
                  <h3 className="item__title c-text-16">
                    <FormattedMessage id="specialOffers.sec1.benL.title" />
                  </h3>
                  <p className="item__description">
                    <FormattedMessage id="specialOffers.sec1.benL.save" /> <span>150€</span>
                  </p>
                  <div className="benefit-wrapper">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="specialOffers.sec1.benL.text1" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="specialOffers.sec1.benL.text2" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="specialOffers.sec1.benL.text3" />
                      </p>
                    </div>
                  </div>
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <span>
                          <FormattedMessage id="menu.signUpNow" />
                        </span>
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="item">
                  <h3 className="item__title c-text-16">
                    <FormattedMessage id="specialOffers.sec1.benR.title" />
                  </h3>
                  <p className="item__description">
                    <span>100€</span> <FormattedMessage id="specialOffers.sec1.benR.discountEach" />
                  </p>
                  <div className="benefit-wrapper">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="specialOffers.sec1.benR.text1" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        {' '}
                        <FormattedMessage id="specialOffers.sec1.benR.text2" />
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="specialOffers.sec1.benR.text3" />
                      </p>
                    </div>
                  </div>
                  {/* <Link to="/apply/" className="c-btn c-btn--red">
                    <span>Sign Up Now</span>
                    <IconArrowRight />
                  </Link> */}
                  <FormattedMessage id="url.apply.index">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        <span>
                          <FormattedMessage id="menu.signUpNow" />
                        </span>
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section">
          <div className="container" />
        </section>
      </div>
    </Layout>
  );
};

export default SpecialOffers;
