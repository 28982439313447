export const COURSE_FEE = 0;
export const FIRST_SET_OF_BOOKS = 40;
export const PAYMENT_BY_INSULMENTS_VALUE = 350;


export const COURSES_DURATION_AND_PRICES = {
    "Part-Time 4": {
        "3 months": 360,
        "6 months": 650,
        "9 months": 900,
        "12 months": 1100
    },
    "Semi-intensive 10": {
        "2 weeks": 170,
        "3 weeks": 255,
        "4 weeks": 320,
        "5 weeks": 400,
        "6 weeks": 480,
        "7 weeks": 560,
        "8 weeks": 600,
        "9 weeks": 675,
        "10 weeks": 750,
        "11 weeks": 825,
        "12 weeks": 890,
        "13 weeks": 962,
        "14 weeks": 1036,
        "15 weeks": 1080,
        "16 weeks": 1120,
        "17 weeks": 1190,
        "18 weeks": 1242,
        "19 weeks": 1292,
        "20 weeks": 1300,
        "21 weeks": 1365,
        "22 weeks": 1430,
        "23 weeks": 1495,
        "24 weeks": 1560,
        "25 weeks": 1625,
        "26 weeks": 1690,
        "27 weeks": 1755,
        "28 weeks": 1820,
        "29 weeks": 1885,
        "30 weeks": 1920,
        "31 weeks": 1984,
        "32 weeks": 2048,
        "33 weeks": 2112,
        "34 weeks": 2176,
        "35 weeks": 2240,
        "36 weeks": 2304,
        "37 weeks": 2331,
        "38 weeks": 2394,
        "39 weeks": 2457,
        "40 weeks": 2520,
        "41 weeks": 2583,
        "42 weeks": 2646,
        "43 weeks": 2709,
        "44 weeks": 2684,
        "45 weeks": 2745,
        "46 weeks": 2780,
        "47 weeks": 2840,
        "48 weeks": 2860,
        "49 weeks": 2920,
        "50 weeks": 2980,
        "51 weeks": 3040,
        "52 weeks": 3100
    },
    "Intensive 20": {
        "2 weeks": 320,
        "3 weeks": 480,
        "4 weeks": 580,
        "5 weeks": 720,
        "6 weeks": 860,
        "7 weeks": 980,
        "8 weeks": 1120,
        "9 weeks": 1260,
        "10 weeks": 1400,
        "11 weeks": 1540,
        "12 weeks": 1560,
        "13 weeks": 1690,
        "14 weeks": 1820,
        "15 weeks": 1950,
        "16 weeks": 1920,
        "17 weeks": 2040,
        "18 weeks": 2160,
        "19 weeks": 2280,
        "20 weeks": 2300,
        "21 weeks": 2415,
        "22 weeks": 2530,
        "23 weeks": 2645,
        "24 weeks": 2640,
        "25 weeks": 2750,
        "26 weeks": 2860,
        "27 weeks": 2970,
        "28 weeks": 2940,
        "29 weeks": 3045,
        "30 weeks": 3150,
        "31 weeks": 3255,
        "32 weeks": 3296,
        "33 weeks": 3399,
        "34 weeks": 3502,
        "35 weeks": 3605,
        "36 weeks": 3672,
        "37 weeks": 3774,
        "38 weeks": 3876,
        "39 weeks": 3978,
        "40 weeks": 4040,
        "41 weeks": 4141,
        "42 weeks": 4242,
        "43 weeks": 4343,
        "44 weeks": 4400,
        "45 weeks": 4500,
        "46 weeks": 4600,
        "47 weeks": 4700,
        "48 weeks": 4800,
        "49 weeks": 4900,
        "50 weeks": 5000,
        "51 weeks": 5100,
        "52 weeks": 5200
    },
    "Long-term 20 hours & Visa Service": {
        "4 months": 1348,
        "5 months": 1680,
        "6 months": 2010,
        "7 months": 2340,
        "8 months": 2660,
        "9 months": 2990,
        "10 months": 3310,
        "11 months": 3630,
        "12 months": 3950
    },
    "Conversation Class": {
        "4 weeks": 64,
        "8 weeks": 120,
        "12 weeks": 150,
    },
    "DELE Preparation": {
        "2 weeks": 450,
        "3 weeks": 620,
        "4 weeks": 800,
    },
    "Private Classes": {
        "1 hour": 35,
        "10 hours": 300,
    },

}
