import React, { forwardRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { CartProvider, useCart } from 'react-use-cart';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import countries from 'countries-list';
import addToMailchimp from 'gatsby-plugin-mailchimp';
// import countries from 'countries-list';
// import format from 'date-fns/format';

import Layout from '../components/Layout';
import Link from '/src/components/LocalizedLink';
import Dropdown from '../components/Dropdown';

import img4WeekAcc from '../assets/images/course-selection-form/4 Weeks Accommodation.png';
import img100OnlineTrinity from '../assets/images/course-selection-form/Online-Trinity-CertTESOL.png';
import imgAirPickup from '../assets/images/course-selection-form/Airport Pick Up.png';
import imgCulturalActivity from '../assets/images/course-selection-form/Cultural Activity.png';
import imgEmpadroniamento from '../assets/images/course-selection-form/Empadronamiento Service.png';
import imgExpression from '../assets/images/course-selection-form/Expression of Interest.png';
import imgNIE from '../assets/images/course-selection-form/NIE & TIE Service.png';
import imgTrinityInPerson from '../assets/images/course-selection-form/Trinity CertTESOL In Person.png';
import imgTrinityInPerson1 from '../assets/images/course-selection-form/Trinity CertTESOL In Person-1.png';
import imgTeacherAndSpanishCourse from '../assets/images/course-selection-form/kenny-eliason-zFSo6bnZJTw-unsplash.jpg';
import imgCalendar from '../assets/images/course-selection-form/mycalendar.svg';

import 'react-datepicker/dist/react-datepicker.css';
import '../assets/styles/pages/course-selection-form-page.scss';

const FieldValidationErrorMsg = ({ field }) =>
  field ? <div className="error-val">{field.message}</div> : null;

const mockCards = [
  {
    id: 1,
    courseTitle: 'Trinity CertTESOL In Person',
    courseDescription: '4-week, 160-hour, in-person Trinity CertTESOL course.',
    price: 1650,
    thumb: imgTrinityInPerson,
    backgroundColor: '#f5f9fc',
    zohoItemId: '3197252000000101532',
  },
  {
    id: 2,
    courseTitle: '100% Online Trinity CertTESOL',
    courseDescription: '12-week, 160-hour, 100% online Trinity CertTESOL course.',
    price: 1550,
    thumb: img100OnlineTrinity,
    backgroundColor: '#fffded',
    zohoItemId: '3197252000000104182',
  },
  {
    id: 3,
    courseTitle: 'Developing Teacher Course',
    courseDescription: '1-12 months Developing Teacher Course. 150€/month 1500€/year.',
    price: 150,
    months: 3,
    thumb: imgTrinityInPerson1,
    backgroundColor: '#f9f2fa',
    zohoItemId: '3197252000000104002',
  },
  {
    id: 11,
    courseTitle: 'Developing Teacher & Spanish Course',
    courseDescription:
      '6 or 12 months Developing Teacher & Spanish Course. 6 months 2000€ or 12 months 3000€',
    price: 1000,
    months: 6,
    thumb: imgTeacherAndSpanishCourse,
    backgroundColor: '#f9f2fa',
    // zohoItemId: '3197252000000104002', // TODO: Change zoho item id
  },
  {
    id: 4,
    courseTitle: '4 Weeks Accommodation Barcelona',
    courseDescription: '4 weeks accommodation in a central Barcelona shared flat.',
    price: 750,
    thumb: img4WeekAcc,
    backgroundColor: '#f4fcf0',
    zohoItemId: '3197252000000101633',
  },
  {
    id: 10,
    courseTitle: '4 Weeks Accommodation Seville',
    courseDescription: '4 weeks accommodation in a central Seville shared flat.',
    price: 550,
    thumb: img4WeekAcc,
    backgroundColor: '#f4fcf0',
    zohoItemId: '3197252000000688028',
  },
  {
    id: 5,
    courseTitle: 'Airport Pick Up',
    courseDescription: 'Airport transfer to your accomodation.',
    price: 60,
    thumb: imgAirPickup,
    backgroundColor: '#fffded',
    zohoItemId: '3197252000000101642',
  },
  {
    id: 6,
    courseTitle: 'Empadronamiento Service',
    courseDescription: 'Empadronamiento appointment and paperwork assistance.',
    price: 60,
    thumb: imgEmpadroniamento,
    backgroundColor: '#f0effc',
    zohoItemId: '3197252000000101939',
  },
  {
    id: 7,
    courseTitle: 'NIE & TIE Service',
    courseDescription: 'NIE & TIE appointment and paperwork assistance.',
    price: 140,
    thumb: imgNIE,
    backgroundColor: '#f8ecec',
    zohoItemId: '3197252000000104146',
  },
  {
    id: 8,
    courseTitle: 'Expression of Interest',
    courseDescription: 'Express your interest in Spanish classes at our partner language schools.',
    price: 0,
    thumb: imgExpression,
    backgroundColor: '#f5f9fc',
    zohoItemId: '3197252000000366165',
  },
  {
    id: 9,
    courseTitle: 'Cultural Activity',
    courseDescription:
      'We organise a range of activities including walking tours, paella cooking classes, flamenco shows, etc.',
    price: 50,
    thumb: imgCulturalActivity,
    backgroundColor: '#f4fcf0',
    zohoItemId: '3197252000000101624',
  },
];

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map((code) => countries.countries[code].name);
const countryNamesOptions = countryNames.sort().map((country) => ({
  value: country,
  label: country,
}));

const monthsOptions = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => ({
  value: month,
  label: month,
}));

const promocodesList = [
  { code: 'Discount50', number: 50, type: 'percentage' },
  { code: 'Fixed1Euro', number: 1, type: 'fixed' },
  { code: 'Devhance1Euro', number: 1, type: 'installable' },
  { code: 'Friend100', number: 100, type: 'fixed' },
];

const ACTIVE_SPECIAL_OFFER_DISCOUNT = 240;
const FULL_PRICE_SPECIAL_OFFER_DISCOUNT = 150;
const PAYMENT_BY_INSULMENTS_VALUE = 450;

const isDevelopingTeacherAndSpanishCourse = (course) =>
  course?.courseTitle === 'Developing Teacher & Spanish Course';

const specialCourseTitles = [
  'Developing Teacher & Spanish Course',
  'Developing Teacher Course',
];

const CourseSelectionForm = ({ data }) => {
  const { inCart, cartTotal, addItem, getItem, items, updateItemQuantity, removeItem } = useCart();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setError] = useState(null);
  const { register, handleSubmit, errors, control } = useForm();
  const [intendedStartDate, setIntendedStartDate] = useState(new Date());
  // const [intendedEndDate, setIntendedEndDate] = useState(new Date('2014/02/10'));

  const [finalPrice, setFinalPrice] = useState(cartTotal);
  const [paymentOffer, setPaymentOffer] = useState({
    payment: 'fullPrice',
    appliedDeposit: false,
    isActive: false,
  });

  const [coursesWithQuantity, setCoursesWithQuantity] = useState(() => {
    const findCoursesWithMonth = items.filter((course) => course.hasOwnProperty('months'));
    const getPersistedCourses = findCoursesWithMonth.map((course) => getItem(course?.id));

    if (!!getPersistedCourses) {
      return getPersistedCourses.map((course) => {
        if (isDevelopingTeacherAndSpanishCourse(course)) {
          return {
            quantity: course.quantity === 2 ? 6 : 12,
            courseId: course.id,
          };
        }
        return {
          quantity: course.quantity,
          courseId: course.id,
        };
      });
    }

    return [
      { quantity: 6, courseId: null },
      { quantity: 6, courseId: null },
    ];
  });
  const [promocodeName, setPromocodeName] = useState('');
  const [promocode, setPromocode] = useState(null);

  const allPrismicCourses = data.allPrismicCourses.edges;
  const requiredCoursesTitles = ['Trinity CertTESOL In Person', '100% Online Trinity CertTESOL'];

  useEffect(() => {
    if (paymentOffer?.appliedDeposit || paymentOffer?.isActive) return;

    setFinalPrice(handleDiscountedFinalPrice(paymentOffer, finalPrice));
  }, [paymentOffer]);

  useEffect(() => {
    if (promocode?.active) {
      if (promocode.type === 'percentage') {
        const price = (finalPrice - (finalPrice / 100) * promocode.number).toFixed(2);
        return setFinalPrice(price);
      }
      if (promocode.type === 'fixed') {
        const price = (finalPrice - promocode.number).toFixed(2);
        return setFinalPrice(price);
      }
      if (promocode.type === 'installable') {
        return setFinalPrice(promocode.number);
      }
    } else {
      // TODO: add a handler for the removed promocode
    }
  }, [promocode]);

  useEffect(() => {
    const specialCourses = coursesWithQuantity?.map((course) => getItem(course.courseId));
    const teacherCourse = specialCourses?.find(
      (course) => course?.courseTitle === 'Developing Teacher Course'
    );

    if (teacherCourse?.quantity === 12 && inCart(teacherCourse?.id)) {
      setFinalPrice(handleDiscountedFinalPrice(paymentOffer, cartTotal, true));
    } else {
      setFinalPrice(handleDiscountedFinalPrice(paymentOffer, cartTotal));
    }
  }, [items.length, cartTotal, coursesWithQuantity]);

  const handleItem = (item, { isSpecial = false, quantity: itemQuantity = 3 }) => {
    if (inCart(item.id)) {
      removeItem(item.id);
    } else {
      const isTeacherAndSpanishCourse = isDevelopingTeacherAndSpanishCourse(item);
      if (isTeacherAndSpanishCourse) {
        const formattedQuantityForUseCart = itemQuantity === 6 ? 2 : 3;
        addItem(item, formattedQuantityForUseCart);
      } else {
        addItem(item, isSpecial ? itemQuantity : 1);
      }
    }

    if (isSpecial) {
      const courseAlredyExists = coursesWithQuantity.some((course) => course.courseId === item.id);
      setCoursesWithQuantity(
        courseAlredyExists
          ? coursesWithQuantity.map((course) =>
              course.courseId === item.id ? { ...course, quantity: itemQuantity } : course
            )
          : [...coursesWithQuantity, { quantity: itemQuantity, courseId: item.id }]
      );
    }

    setPromocode(null);
    setPaymentOffer((prev) => ({ ...prev, isActive: false, appliedDeposit: false }));
  };

  const handleChangeItemQuantity = ({ value }, courseId) => {
    setCoursesWithQuantity(
      coursesWithQuantity.map((course) =>
        course.courseId === courseId ? { quantity: value, courseId } : course
      )
    );

    const teacherAndSpanishCourse = isDevelopingTeacherAndSpanishCourse(
      coursesWithQuantity.find((course) => course.courseId === courseId) && getItem(courseId)
    );

    if (teacherAndSpanishCourse) {
      const formattedMonthsForUseCart = value === 6 ? 2 : 3;

      inCart(courseId)
        ? updateItemQuantity(courseId, formattedMonthsForUseCart)
        : handleItem(teacherAndSpanishCourse, {
            isSpecial: specialCourseTitles.includes(teacherAndSpanishCourse.courseTitle),
            quantity: formattedMonthsForUseCart,
          });
      return;
    }

    const selectedCourse = mockCards.find((c) => c.id === courseId);
    inCart(courseId)
      ? updateItemQuantity(courseId, value)
      : handleItem(selectedCourse, {
          isSpecial: specialCourseTitles.includes(selectedCourse.courseTitle),
          quantity: value,
        });
  };

  const handleDiscountedFinalPrice = (paymentOffer, finalPrice, activeSpecialOffer = false) => {
    const checkTotalPayment =
      paymentOffer?.payment === 'fullPrice' && finalPrice <= FULL_PRICE_SPECIAL_OFFER_DISCOUNT;

    if (paymentOffer.appliedDeposit) return PAYMENT_BY_INSULMENTS_VALUE;

    if (activeSpecialOffer)
      return checkTotalPayment
        ? finalPrice
        : finalPrice - FULL_PRICE_SPECIAL_OFFER_DISCOUNT - ACTIVE_SPECIAL_OFFER_DISCOUNT;

    return checkTotalPayment ? finalPrice : finalPrice - FULL_PRICE_SPECIAL_OFFER_DISCOUNT;
  };

  const handleSubmitPromocode = async () => {
    if (promocodeName && !promocode?.active) {
      let findPromocode = promocodesList.find(
        (item) => item.code.toLowerCase() === promocodeName.toLowerCase()
      );
      if (!findPromocode) {
        // const response = await fetch('/api/promo/check', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ promocodeName }),
        // });
        // const jsonResponse = await response.json();
        // if (jsonResponse.success && jsonResponse.data) {
        //   findPromocode = jsonResponse.data;
        // }
        // setPromocode(null);
      }
      if (findPromocode) {
        setPromocode({ ...findPromocode, active: true });
        return;
      }
      setPromocode({ error: true });
    }
  };

  const fetchPaycomet = async (data) => {
    function slugify(text) {
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\u0100-\uFFFF\w\-]/g, '-') // Remove all non-word chars ( fix for UTF-8 chars )
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '');
    }

    function getDiscount() {
      if (promocode.type === 'percentage') {
        return `${promocode.number}%`;
      }
      if (promocode.type === 'fixed') {
        return +(promocode.number / totalItems).toFixed(2);
      }
    }

    const reqPaycometData = {
      customer: {
        name: data.firstName,
        surname: data.lastName,
        email: data.userEmail,
      },
      shoppingCart:
        items?.map((item) => ({
          sku: slugify(`Course Selection Form ${item.courseTitle} ${item.id}`),
          quantity: item.quantity,
          unitPrice: item.price,
          name: item.courseTitle,
          category: 'Course Selection Form',
          articleType: 8,
        })) || [],
      discount: promocode?.active ? getDiscount() : '',
      deposit:
        paymentOffer?.payment === 'deposit'
          ? `Applied deposit of ${PAYMENT_BY_INSULMENTS_VALUE}e`
          : `Full price paid, got a ${FULL_PRICE_SPECIAL_OFFER_DISCOUNT}e discount`,
      amount: paymentOffer.appliedDeposit
        ? (PAYMENT_BY_INSULMENTS_VALUE * 100).toString()
        : (finalPrice * 100).toString(),
      productDescription: 'Course Selection Form',
      orderId: `FORM-${Date.now()}`,
    };

    const responsePaycomet = await fetch('/api/paycomet/form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqPaycometData),
    });

    return JSON.parse(await responsePaycomet.json());
  };

  const addToCRM = async (data) => {
    const crmData = {
      firstName: data.firstName,
      lastName: data.lastName,
      userEmail: data.userEmail,
      country: data.country.value,
      startDate: intendedStartDate,
      promo: promocode?.code ?? '',
      items: items.map((item) => ({ courseTitle: item.courseTitle, quantity: item.quantity })),
      paymentOffer: paymentOffer?.payment === 'deposit' ? 'Deposit' : 'Full Payment',
      userFromGoogleAds: window?.userFromGoogleAds,
      userFromFacebookAds: window?.userFromFacebookAds,
    };

    console.log('crmData: ', crmData);
    const response = await fetch('/api/course-order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(crmData),
    });
    const jsonResponse = await response.json();

    if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
      const MailchimpData = {
        FNAME: data.firstName,
        LNAME: data.lastName,
        MMERGE4: new Date().toISOString().split('T')[0], // contact date
        MMERGE17: 'Application form', // Source
      };
      addToMailchimp(data.userEmail, MailchimpData);
    } else {
      console.log('Monday Error', jsonResponse);
      throw new Error(`Monday Error: ${JSON.stringify(jsonResponse.error)}`);
    }
  };

  const addToZoho = async (data) => {
    const reqZohoData = {
      contact_name: data.firstName + ' ' + data.lastName,
      first_name: data.firstName,
      last_name: data.lastName,
      student_email: data.userEmail,
      country: data.country.value,
      discount:
        Number(cartTotal) -
        Number(paymentOffer.appliedDeposit ? PAYMENT_BY_INSULMENTS_VALUE : finalPrice),
      adjustment:
        Number(paymentOffer.appliedDeposit ? PAYMENT_BY_INSULMENTS_VALUE : finalPrice) -
        Number(cartTotal),
      line_items: items?.map((item) => ({
        item_id: item.zohoItemId,
        description: item.courseDescription,
        rate: item.price,
        quantity: item.quantity,
      })),
    };

    const responseZoho = await fetch('/api/zoho/invoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqZohoData),
    });

    const responseZohoJSON = await responseZoho.json();

    if (responseZohoJSON?.invoice_url && responseZohoJSON?.message === 'Success') {
      setError(false);
      window.fbq('track', 'SubmitApplication');
      window.location.href = responseZohoJSON.invoice_url;
      // addToMailchimp(data.userEmail, MailchimpData);
    } else {
      console.log('Zoho Error', responseZohoJSON);
      throw new Error(`Zoho Error: ${JSON.stringify(responseZohoJSON.error)}`);
    }
  };

  const onSubmit = async (data) => {
    // Enable loading
    setIsLoading(true);

    try {
      await addToCRM(data);
      await addToZoho(data);
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
      setError(error ? error.message || error : 'Oops, something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const isCardRequired = (courseCard) => {
    const makeRestCardsUnrequired = !mockCards.some(
      (card) => requiredCoursesTitles.includes(card.courseTitle) && inCart(card.id)
    );

    return requiredCoursesTitles.includes(courseCard.courseTitle) && makeRestCardsUnrequired;
  };

  return (
    <FormLayout
      handleItem={handleItem}
      handleChangeItemQuantity={handleChangeItemQuantity}
      handleSubmitPromocode={handleSubmitPromocode}
      promocodeName={promocodeName}
      setPromocodeName={setPromocodeName}
      coursesWithQuantity={coursesWithQuantity}
      intendedStartDate={intendedStartDate}
      setIntendedStartDate={setIntendedStartDate}
      register={register}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      onSubmit={onSubmit}
      inCart={inCart}
      finalPrice={finalPrice}
      paymentOffer={paymentOffer}
      setPaymentOffer={setPaymentOffer}
      isCardRequired={isCardRequired}
    />
  );
};

const FormLayout = ({
  handleItem,
  handleChangeItemQuantity,
  handleSubmitPromocode,
  promocodeName,
  setPromocodeName,
  coursesWithQuantity,
  intendedStartDate,
  setIntendedStartDate,
  register,
  control,
  handleSubmit,
  errors,
  isError,
  isSuccess,
  isLoading,
  onSubmit,
  inCart,
  finalPrice,
  paymentOffer,
  setPaymentOffer,
  isCardRequired,
}) => {
  return (
    <Layout
      title="Learn to Teach English (TEFL) in Barcelona! Apply Now"
      description="Become the TEFL teacher you want to be by applying for TEFL Iberia's friendly"
    >
      <div className="course-selection-form-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">Course Selection Form</h1>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <form className="forms" onSubmit={handleSubmit(onSubmit)}>
              <div className="form">
                <div>
                  <label className="form__first-name">
                    <p className="c-text-14 text-under">First Name</p>
                    <input
                      className="input__first-name"
                      type="text"
                      placeholder="John"
                      name="firstName"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <FieldValidationErrorMsg field={errors.firstName} />
                  </label>
                  <label className="form__last-name">
                    <p className="c-text-14 text-under">Last Name</p>
                    <input
                      className="input__last-name"
                      type="text"
                      placeholder="Smith"
                      name="lastName"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <FieldValidationErrorMsg field={errors.lastName} />
                  </label>
                  <label className="form__first-name form__first-name--block">
                    <p className="c-text-14 text-under">Email address*</p>
                    <input
                      className="input__first-name"
                      type="email"
                      placeholder="johnsmith@gmail.com"
                      name="userEmail"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <FieldValidationErrorMsg field={errors.userEmail} />
                  </label>

                  <label className="form__first-name form__first-name--block">
                    <p className="c-text-14 text-under">Country*</p>
                    <Controller
                      name="country"
                      render={({ ref, ...props }) => (
                        <Dropdown
                          inputRef={ref}
                          options={countryNamesOptions}
                          placeholder="Select your country"
                          isSearchable
                          {...props}
                        />
                      )}
                      control={control}
                      defaultValue={null}
                      rules={{
                        required: 'This field is required.',
                      }}
                    />
                    <FieldValidationErrorMsg field={errors.userNationality} />
                  </label>

                  <div className="devider-line"></div>

                  <h1 className="c-title-22 form__title">Intended Start Date</h1>
                  <div className="start-end-wrapper">
                    <div className="start">
                      <h3 className="c-text-14 text-under">Start date</h3>
                      <DatePicker
                        selected={intendedStartDate}
                        onChange={(date) => setIntendedStartDate(date)}
                        customInput={<CustomDateInput />}
                        startDate={intendedStartDate}
                      />
                    </div>
                  </div>
                  <p className="form__subtitle">
                    Which of the following would you like to include in your course program? (You
                    can select more than one)
                  </p>
                </div>

                <div className="cards-list">
                  {mockCards.map((item, index) => {
                    let formattedQuantity;
                    const courseWithQuantity = coursesWithQuantity.find(
                      (course) => course.courseId === item.id
                    );

                    const teacherAndSpanishCourse = isDevelopingTeacherAndSpanishCourse(item);

                    const formattedMonthsOptions = teacherAndSpanishCourse
                      ? monthsOptions.filter((month) => month.value === 6 || month.value === 12)
                      : monthsOptions;

                    const finalCourseTeacherAndSpanishPrice =
                      teacherAndSpanishCourse && courseWithQuantity?.quantity === 6
                        ? item.price * 2
                        : item.price * 3;

                    if (teacherAndSpanishCourse && courseWithQuantity?.quantity === 3) {
                      formattedQuantity = 12;
                    } else if (teacherAndSpanishCourse && courseWithQuantity?.quantity === 2) {
                      formattedQuantity = 6;
                    } else {
                      formattedQuantity = courseWithQuantity?.quantity;
                    }

                    const cardId = `card-${item.id}`;
                    return (
                      <div key={index}>
                        {index === 4 && <h3 className="form__subtitle">Additional services:</h3>}
                        <div className="card" key={item.id}>
                          <label
                            style={{ backgroundColor: item.backgroundColor }}
                            className="card__wrapper"
                          >
                            <img className="card__thumb" src={item.thumb} alt={item.courseTitle} />
                            <div className="card__content">
                              <div>
                                <input
                                  id={cardId}
                                  type="checkbox"
                                  checked={inCart(item.id)}
                                  name="course"
                                  ref={register}
                                  required={isCardRequired(item)}
                                  onChange={() =>
                                    handleItem(item, {
                                      isSpecial: specialCourseTitles.includes(item.courseTitle),
                                      quantity: item?.months && courseWithQuantity?.quantity,
                                    })
                                  }
                                />
                                <div className="card__label">
                                  {isCardRequired(item) && (
                                    <p className="card__warning">
                                      Please select Trinity CertTESOL in Person or online to
                                      continue
                                    </p>
                                  )}
                                  <h3 className="card__title">{item.courseTitle}</h3>
                                  <p className="card__description">{item.courseDescription}</p>
                                </div>
                              </div>
                              <div className="card__meta">
                                <p className="card__price">
                                  {/* TODO: FIX /month bug */}
                                  &euro;
                                  {teacherAndSpanishCourse
                                    ? finalCourseTeacherAndSpanishPrice
                                    : item.price}{' '}
                                  {item?.months && !teacherAndSpanishCourse ? '/ mo' : ''}
                                </p>
                              </div>
                            </div>
                          </label>
                          <div className="card__meta card__meta--length">
                            {item?.months && (
                              <div className="month-container">
                                <p className="month">Months</p>
                                <Dropdown
                                  options={formattedMonthsOptions}
                                  name="courseLength"
                                  placeholder={'Select'}
                                  className="card__dropdown"
                                  onChange={(data) => handleChangeItemQuantity(data, item.id)}
                                  value={{
                                    value: formattedQuantity,
                                    label: formattedQuantity,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {isCardRequired(item) && (
                            <FieldValidationErrorMsg field={errors.agreeRules} />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="devider-line" />

                <label className="form__first-name promotional-code">
                  <p className="c-text-14 text-under">Do you have a referral or promotion code?</p>
                  <input
                    className="input__first-name"
                    type="text"
                    placeholder="Please enter your code or leave blank."
                    name="promotionalCode"
                    value={promocodeName}
                    onChange={({ target: { value } }) => setPromocodeName(value)}
                  />
                  <button
                    type="button"
                    className="promotional-code__btn"
                    onClick={handleSubmitPromocode}
                  >
                    Apply Promotional Code
                  </button>
                </label>

                <h3 className="form__subtitle"> I want to pay*</h3>
                <div className="form__radio">
                  <input
                    id="course-radio-1"
                    type="radio"
                    name="course"
                    value="fullPrice"
                    checked={paymentOffer.payment === 'fullPrice'}
                    onChange={() =>
                      setPaymentOffer({
                        payment: 'fullPrice',
                        isActive: true,
                        appliedDeposit: false,
                      })
                    }
                    ref={register({
                      required: 'This field is required.',
                    })}
                  />
                  <label htmlFor="course-radio-1">
                    <span className="label">
                      {' '}
                      I would like to pay in full today and save{' '}
                      <b>&euro;{FULL_PRICE_SPECIAL_OFFER_DISCOUNT}</b>
                    </span>
                  </label>
                </div>
                <div className="form__radio">
                  <input
                    id="course-radio-2"
                    type="radio"
                    name="course"
                    value="deposit"
                    checked={paymentOffer.payment === 'deposit'}
                    onChange={() => setPaymentOffer({ payment: 'deposit', appliedDeposit: true })}
                    ref={register({
                      required: 'This field is required.',
                    })}
                  />
                  <label htmlFor="course-radio-2">
                    <span className="label">
                      I would like to pay a deposit of <b>&euro;{PAYMENT_BY_INSULMENTS_VALUE}</b>
                    </span>
                  </label>
                </div>

                <div className="devider-line"></div>
                <div className="total">
                  <h3>
                    Total:{' '}
                    <span className="total__price">
                      &euro;{' '}
                      {paymentOffer.appliedDeposit ? PAYMENT_BY_INSULMENTS_VALUE : finalPrice || 0}
                    </span>
                  </h3>
                </div>
                <div className="form__checkbox terms">
                  <input
                    id="agree-checkbox-1"
                    type="checkbox"
                    required
                    value={true}
                    name="agreeRules"
                    ref={register({ required: 'This field is required.' })}
                  />
                  <label htmlFor="agree-checkbox-1">
                    <span className="label">
                      I have read and agree with TEFL Iberia's
                      <Link to="/apply/terms" className="red-link">
                        {' '}
                        Terms and Conditions
                      </Link>{' '}
                    </span>
                  </label>
                  <FieldValidationErrorMsg field={errors.agreeRules} />
                </div>
              </div>

              <button
                type="submit"
                className="c-btn c-btn--red"
                disabled={Object.keys(errors).length > 0}
              >
                {isLoading ? <i className="spinner" /> : 'Go To Payment'}
              </button>

              {isSuccess && (
                <div className="success-msg">
                  <span> Your application has been successfully submitted!</span>
                </div>
              )}

              {isError && (
                <div className="error-msg">
                  <span>{JSON.stringify(isError)}</span>
                </div>
              )}
              {Object.keys(errors).length > 0 && (
                <div className="error-msg">
                  <span>Some fields are missing or contain errors.</span>
                </div>
              )}
            </form>
          </div>
        </section>
      </div>
    </Layout>
  );
};

const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
  <button className="example-custom-input" type="button" onClick={onClick} ref={ref}>
    <span className="icon-wrapper">
      <img src={imgCalendar} alt={'Calendar'} />
    </span>
    {value}
  </button>
));

export const query = graphql`
  query {
    allPrismicCourses(sort: { order: ASC, fields: data___from }) {
      edges {
        node {
          data {
            show_on_these_pages {
              page
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            name {
              text
            }
          }
        }
      }
    }
  }
`;

const CourseSelectionFormContainer = ({ data }) => (
  <CartProvider id="course-selection-form">
    <CourseSelectionForm data={data} />
  </CartProvider>
);

export default CourseSelectionFormContainer;
