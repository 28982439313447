import React from 'react';
import { graphql } from 'gatsby';
// import { FormattedMessage } from 'react-intl';

import Layout from '../../components/Layout';
// import { SectionInstagramPosts } from '../../components/InstagramPosts';
import { parseYoutubeLink } from '../../utils/common';

import iconTick from '../../assets/images/svg/tick.svg';
import imgGraduates from '../../assets/images/graduates/Graduates_main.jpeg';

import '../../assets/styles/pages/tefl-iberia-graduates-page.scss';

const Graduates = ({ data }) => {
  const allPrismicGraduates = data.allPrismicGraduates.edges;
  console.log('allPrismicGraduates: ', allPrismicGraduates);
  const firstGraduate = allPrismicGraduates.filter(
    ({
      node: {
        data: { pinned },
      },
    }) => pinned === true
  );
  const otherGraduates = allPrismicGraduates.filter(
    ({
      node: {
        data: { pinned },
      },
    }) => pinned !== true
  );

  // const allPrismicInstagramPosts = data.allPrismicInstagramPosts.edges;

  return (
    <Layout
      title={'Iberia Language Academy Reviews & Testimonials'}
      description={
        'At ILA, we offer high-quality, supportive Spanish courses and a life-changing opportunity to learn in Barcelona. Hear it from our students first hand.'
      }
    >
      <div className="tefl-iberia-graduates-page">
        <section className="first-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="block_l c-benefit-list ">
                  <h1 className="c-title-42 first-section__title">
                    Iberia Language Academy Testimonials
                  </h1>
                  <p className="c-text-18 first-section__description">
                    We love creating memorable experiences here at ILA. We pride ourselves on
                    providing high-quality Spanish lessons, an essential support system and most
                    importantly a life-changing opportunity to spend time in Barcelona. Hear it from
                    our students first hand.
                  </p>
                  <h4>A common theme you’ll here in all student reviews:</h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">Feeling confident to speak Spanish in public</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">Improved fluency and ability to express themselves</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">Friendly and supportive teachers</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">Excellent support from the coordination team</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {/* {firstGraduate.map(({ node }) => ( */}
                <div className="block_r">
                  <div className="block_r__video">
                    <img src={imgGraduates} alt="Iberia Language Academy Testimonials" />
                    {/* <iframe
                        width="560"
                        height="315"
                        src={parseYoutubeLink(node?.data?.video?.embed_url)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      /> */}
                  </div>
                  {/* <h3 className="block_r__title">{node?.data?.title?.text}</h3> */}
                  {/* <p className="block_r__description">{node?.data?.description?.text}</p> */}
                </div>
                {/* ))} */}
              </div>
            </div>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <div className="row">
              {otherGraduates.map(({ node }) => (
                <div className="col-lg-4 col-sm-6 col-12" key={node.id}>
                  <div className="review-item">
                    <div className="review-item__video">
                      <img
                        className="review-item__image"
                        src={node?.data?.thumbnail?.url}
                        alt={node?.data?.title?.text}
                      />
                      {/* <iframe
                        width="560"
                        height="315"
                        src={parseYoutubeLink(node?.data?.video?.embed_url)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      /> */}
                    </div>
                    <h3 className="c-text-16 review-item__title">{node?.data?.title?.text}</h3>
                    <p className="c-text-14 review-item__description">
                      {node?.data?.description?.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicGraduates(sort: { order: DESC, fields: last_publication_date }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            description {
              text
            }
            pinned
            thumbnail {
              url
            }
            video {
              embed_url
            }
          }
        }
      }
    }
  }
`;

export default Graduates;
