import React from 'react';
import { FormattedMessage } from 'react-intl';

// import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';
import Faq from '../components/Faq';
// import ReviewsSection from '../components/ReviewsSection';

// import useLocale from '../hooks/useLocale';

// import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';

import imgRmain from '../assets/images/accomodation/Photo.jpg';
// import imgDots from '../assets/images/application-process/Dots 3.svg';
import imageAccomodationOption_1 from '../assets/images/accomodation/item1.png';
import imageAccomodationOption_2 from '../assets/images/accomodation/item2.png';

import '../assets/styles/pages/accomodation-page.scss';
// import { URL_KEYS_ROUTES_EN } from '../constants/routes';

const faqs = [
  {
    id: 1,
    title: 'Can I look for my own accommodation?',
    description:
      'Yes, we are happy to share resources and local accommodation portals so you can research and find your own accommodation.',
  },
  {
    id: 2,
    title: 'Where is the accommodation?',
    description:
      'All the accommodation we use is within 30 minutes by walking or public transport from the school. We will share the exact address with you once your booking is confirmed.',
  },
  {
    id: 3,
    title: 'Who will I be sharing with?',
    description: `In a shared flat you’ll be sharing with international people of a similar age. We take care to carefully select the age and profile of your flatmates. In many cases you will be sharing with people on your course. <br/><br/>In a family homestay you’ll be staying with a local family.
      `,
  },
  {
    id: 4,
    title: 'What is the accommodation like?',
    description: `You’ll be in a shared apartment, living with 2-3 other people, with all the facilities you’d expect from a modern flat. You’ll have space to study, bedding, privacy, wifi, use of all the kitchen and a shared bathroom.
      In Barcelona and Europe in general, the buildings are older and smaller than you may be accustomed to in your home country. We provide accommodation in line with the standards expected within Spain.
      `,
  },
  {
    id: 5,
    title: 'When can I move in?',
    description:
      'We normally organise move-in dates for the Sunday before your course starts. If you have specific requirements please let us know and we’ll do our best to find a solution for you.',
  },
  {
    id: 6,
    title: 'Can I stay with a friend / partner? Can I bring children?',
    description: `Yes, it is possible to make special arrangements for two or more people, please email us separately at <a class="red-link" target="_blank" href="mailto:classes@ilabarcelona.com">classes@ilabarcelona.com</a> with details.`,
  },
  {
    id: 7,
    title: 'Are bills included?',
    description: `Yes, all bills are included. We do however ask you to be mindful of electricity and water usage, and be sure to turn lights and air con off when not in use, etc. `,
  },
  {
    id: 10,
    title: 'Do I have to take care of cleaning my accommodation? ',
    description: `A cleaner will take care of the common areas, and you are expected to keep your room clean. You are also expected to take care of the day-to-day cleaning, e.g. washing dishes, laundry, etc. `,
  },
  {
    id: 8,
    title: 'Do I have to pay a security deposit? ',
    description: `Yes, we ask for a 200€ security deposit from all tenants.`,
  },
  {
    id: 9,
    title: 'Can I change rooms if I am not happy with it?',
    description: `If you are not satisfied with your accommodation please contact us to discuss it. In most cases small problems can be easily solved. If you still wish to change, you can do as long as we have something similar available, and you have a good reason for changing. In some cases a change is possible, but you might be charged a change fee. We will evaluate this on a case-by-case basis.`,
  },
];

// const faqsEs = [
//   {
//     id: 1,
//     title: '¿Cuánto costará por mes?',
//     description:
//       'Nuestras habitaciones en Barcelona cuestan 750€ por las 4 semanas de tu curso. Existen algunas alternativas más económicas, sujetas a disponibilidad.<br/> <br/> Nuestras habitaciones en Sevilla cuestan 550€ por las 4 semanas de tu curso.',
//   },
//   {
//     id: 2,
//     title: '¿Dónde están los apartamentos?',
//     description:
//       'Las habitaciones están ubicadas en varios lugares alrededor de Barcelona y Sevilla, pero todas son muy céntricas y se encuentran a una distancia máxima de 30 minutos a pie o en transporte público de la escuela.',
//   },
//   {
//     id: 3,
//     title: '¿Con quién compartiré alojamiento?',
//     description:
//       'Normalmente compartirás el apartamento con otros estudiantes, exalumnos o locales que viven aquí. Es genial para recibir consejos de primera mano sobre la vida y el trabajo en Barcelona o Sevilla.',
//   },
//   {
//     id: 4,
//     title: '¿Cómo es el alojamiento?',
//     description:
//       'Estarás en un apartamento compartido con 2-3 personas más, con todas las instalaciones que esperarías de un piso moderno. Tendrás espacio para estudiar, ropa de cama, privacidad, wifi, uso de toda la cocina y un baño compartido.',
//   },
//   {
//     id: 5,
//     title: '¿Cuándo puedo mudarme?',
//     description:
//       'Normalmente organizamos tu mudanza el sábado o domingo antes de la fecha de inicio de tu curso y la salida el domingo después de que termines. También es posible extender antes y después de las fechas del curso, solo avísanos en el cuadro de "información adicional".',
//   },
//   {
//     id: 6,
//     title: '¿Puedo quedarme con un amigo / pareja? ¿Puedo traer niños?',
//     description: `Sí, es posible hacer arreglos especiales para dos o más personas, envíanos un correo electrónico separado a accommodation@tefl-iberia.com con los detalles.`,
//   },
//   {
//     id: 7,
//     title: '¿Están incluidas las facturas?',
//     description: `Sí, todas las facturas están incluidas. Electricidad, gas, agua, wifi, etc. están incluidos. La comida, las comidas y el transporte público no están incluidos.`,
//   },
//   {
//     id: 8,
//     title: '¿Tengo que pagar un depósito de seguridad?',
//     description: `Sí, deberás pagar un depósito de seguridad reembolsable a tu llegada, preferiblemente en efectivo, que se te devolverá cuando te vayas. Normalmente es de 200€, pero algunos anfitriones requieren más. Confirmaremos estos detalles contigo antes de que llegues. `,
//   },
//   {
//     id: 9,
//     title: '¿Puedo tener invitados?',
//     description: `Normalmente no, pero es posible llegar a un acuerdo privado con tu anfitrión.`,
//   },
// ];

const Accomodation = () => {
  // const { enLocale } = useLocale({ matchPathName: '/es/' });
  return (
    <Layout
      title={'Affordable Barcelona Student Accommodation Service | 2025 '}
      description={
        'We partner with a beautiful student residence to provide our students with special rates when planning their Barcelona stay. Flat shares also available.'
      }
    >
      <div className="accomodation-page">
        <section className="first-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <h1 className="c-title-42 first-section__title">
                  <FormattedMessage id="accommodation.sec1.title" />
                </h1>
              </div>
              <div className="col-md-6">
                <p className="c-text-18 first-section__description">
                  The best learning takes place when you are relaxed and focussed. Let us take the
                  stress out of finding a place to live and use our accommodation service! We can
                  place you in <strong> comfortable, affordable accommodation</strong> within 30
                  minutes by walking / public transport from the school.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="second-section c-course-second-section c-course-second-section-pt">
          <div className="course mx-auto">
            <div className="course__container">
              <div className="course__wrapper-inner course__wrapper-inner--md">
                <h3 className="c-title-36">
                Accommodation Options
                </h3>
                <p className="c-text-17 course__description">
                  <FormattedMessage id={'accomodation.2s.1description'} />
                </p>
                <p className="c-text-17 course__description">
                  <FormattedMessage id={'accomodation.2s.2description'} />
                </p>
                <p className="c-text-17 course__description">
                  <FormattedMessage id={'accomodation.2s.3description'} />
                </p>
              </div>
              <div className="course__wrapper-inner">
                <div className="course__image">
                  <img src={imgRmain} alt="imageIntensiveSpanishMain" />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="third-section accomodation-option">
          <h2 className="c-title-34 text-center mb-10">Accommodation Options</h2>
          <div className="container accommodation-image-container">
            <div className="row">
              <div className="col">
                <img className="accommodation-img" src={imgRmain} alt="imageIntensiveSpanishMain" />
              </div>
            </div>
          </div>
          <div className="accomodation-option__container mx-auto">
            {/* --- 1 --- */}
            <div className="offer__wrapper mx-auto">
              <div className="offer__image">
                <img src={imageAccomodationOption_1} alt="Shared flat" />
              </div>
              <div className="offer__description">
                <h3 className="c-title-26">Shared flat</h3>
                <p className="c-text-17">
                  Ideal if you’d like more independence and to meet other people studying and
                  working in Barcelona. Great for making friends, contacts and recommendations for
                  making the most of your time in Barcelona.
                </p>
              </div>
              <div className="offer__price c-title-19">
                <span className="text-fw-600">750€ </span>/4 weeks
              </div>
            </div>
            {/* --- 1 --- */}
            {/* --- 2 --- */}
            <div className="offer__wrapper mx-auto">
              <div className="offer__image">
                <img src={imageAccomodationOption_2} alt="Family Homestay" />
              </div>
              <div className="offer__description">
                <h3 className="c-title-26">Student residence</h3>
                <p className="c-text-17">
                  Iberia Language Academy is partnered with a beautiful, modern student residence to
                  provide our students with special rates and flexibility when planning their stay
                  in Barcelona. Stay in style with an onsite gym, swimming pool, cafeteria and
                  modern rooms!
                </p>
              </div>
              <div className="offer__price c-title-19">
                <span className="text-fw-600">1000€ </span>/4 weeks
              </div>
            </div>
            {/* --- 2 --- */}
            {/* <div className="offer__warn">
              <p className="c-text-16">
                <FormattedMessage
                  id={'accomodation.3s.note'}
                  values={{
                    span: (chunks) => <span className="text-fw-600">{chunks}</span>,
                  }}
                />
              </p>
            </div> */}
          </div>
        </section>

        <section className="c-section-pb-140 fourth-section c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 col-lg-8 mx-auto">
                <h3 className="c-title-34 text-center mb-50">Accommodation FAQs</h3>
                {faqs.map((item, index) => (
                  <Faq key={index} description={item.description} title={item.title} />
                ))}
                <div className="text-center mt-50">
                  {/* <FormattedMessage id={URL_KEYS_ROUTES_EN.FAQ}>
                    {(link) => (
                      <Link to={link[0]} className="c-btn c-btn--white c-btn--green-border ">
                        <FormattedMessage id={'btn.viewMoreFaq'} />
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Accomodation;
